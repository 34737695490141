import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrokerApi } from '../../../shared/api/broker.api';
import { Observable, map } from 'rxjs';
import { DisplaysData } from '../types/displays.type';
import { ApiResponse } from '../../../shared/api/base.api';
import { UnseenData } from '../types/unseen.type';

@Injectable({
    providedIn: 'root'
})
export class NavigationsApi extends BrokerApi {
    constructor(http: HttpClient) {
        super('navigations', http);
    }

    getDisplays(): Observable<DisplaysData> {
        return this.get<ApiResponse<DisplaysData>>('displays').pipe(map(response => response.data));
    }

    getUnseen(): Observable<UnseenData> {
        return this.get<ApiResponse<UnseenData>>('unseen').pipe(map(response => response.data));
    }
}
