import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryViewStore } from './category-view.store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { combineLatest, filter, map } from 'rxjs';
import { ContentsApi } from './api/contents.api';
import { IntroStatus } from './types/contents.type';
import { AsyncPipe, DatePipe, NgClass, NgStyle } from '@angular/common';
import { ChromaLoading, ChromaSlideToggleModule } from 'chroma-ui';
import { Store } from '@ngrx/store';
import * as UISelectors from '../../../core/ui/store/ui.selectors';
import { UIActions } from '../../../core/ui/store/ui.actions';

@Component({
    selector: 'app-category-view',
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        AsyncPipe,
        DatePipe,
        RouterLink,
        ChromaLoading,
        ChromaSlideToggleModule
    ],
    providers: [CategoryViewStore, ContentsApi],
    templateUrl: './category-view.component.html'
})
export class CategoryViewComponent implements OnInit, OnDestroy {
    introStatus = IntroStatus;
    activeIntroStatus = IntroStatus.ALL;
    currentCategoryId: string;

    intros$ = this.categoryViewStore.intros$;
    introsLoading$ = this.categoryViewStore.introsLoading$;
    introsEndReached$ = this.categoryViewStore.introsEndReached$;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private readonly categoryViewStore: CategoryViewStore
    ) {}

    ngOnInit(): void {
        this.route.params.pipe(map(params => params.categoryId)).subscribe(categoryId => {
            this.currentCategoryId = this.currentCategoryId || categoryId;

            this.activeIntroStatus = IntroStatus.ALL;
            this.categoryViewStore.createSession({
                categoryId,
                introStatus: this.activeIntroStatus,
                navigated: this.currentCategoryId !== categoryId,
                filter: false
            });

            this.currentCategoryId = categoryId;
        });

        combineLatest([
            this.categoryViewStore.introsEndReached$,
            this.store.select(UISelectors.selectScrolledToEnd)
        ])
            .pipe(filter(([endReached, multiplier]) => !endReached && multiplier > 0))
            .subscribe(() => this.categoryViewStore.loadNextSet());
    }

    ngOnDestroy(): void {
        this.store.dispatch(UIActions.setInfiniteLoading({ loading: false }));
    }

    filterByStatus(introStatus: IntroStatus, value: boolean): void {
        this.store.dispatch(UIActions.resetScrolledToEnd());

        this.activeIntroStatus = value ? introStatus : IntroStatus.ALL;
        this.categoryViewStore.createSession({
            categoryId: this.route.snapshot.params.categoryId,
            introStatus: this.activeIntroStatus,
            navigated: false,
            filter: true
        });
    }
}
