import { Delta, DeltaOperation } from 'quill';

export interface SessionData {
    sessionId: string;
}

export interface IntrosData {
    result: Array<Intro>;
}

export interface Intro {
    id: string;
    title: string;
    bodySummary: string;
    author: {
        firstName: string;
        lastName: string;
        userProfilePicture: string;
    };
    mustRead: boolean;
    commentsCount: number;
    seen: boolean;
    bookmarked: boolean;
    updatedAt: string;
}

export enum IntroStatus {
    ALL = 'ALL',
    MUST_READ = 'MUST_READ',
    UNREAD = 'UNREAD'
}

export interface ArticleResponse {
    body: Delta;
    author: {
        firstName: string;
        lastName: string;
        userProfilePicture: string;
    };
    createdAt: string;
}

export interface Article {
    id: string;
    title: string;
    body: Delta;
    author: {
        firstName: string;
        lastName: string;
        userProfilePicture: string;
    };
    mustRead: boolean;
    attachments: Array<{
        id: string;
        fileName: string;
        size: number;
    }>;
    comments: Array<{
        body: Delta;
        replies: Array<{
            body: Delta;
            author: {
                firstName: string;
                lastName: string;
                userProfilePicture: string;
            };
            createdAt: string;
        }>;
        author: {
            firstName: string;
            lastName: string;
            userProfilePicture: string;
        };
        createdAt: string;
    }>;
    createdAt: string;
    updatedAt: string;
    seenInformation: {
        seenCount: number;
    };
    bookmarked: boolean;
    products: Array<{
        ids: {
            isin: string;
        };
        name: string;
        link: string;
    }>;
}
