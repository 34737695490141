@if (article(); as article) {
    <div class="tw-flex tw-flex-col tw-gap-y-10 print:tw-gap-y-5">
        <div class="tw-flex tw-flex-col tw-gap-y-5 print:tw-gap-y-2.5">
            <div class="tw-flex tw-flex-col tw-justify-between tw-gap-y-5 sm:tw-flex-row sm:tw-items-center">
                <div class="tw-inline-flex tw-items-center tw-gap-x-2.5">
                    @if (article.author.userProfilePicture) {
                        <img
                            class="tw-h-[50px] tw-w-[50px] tw-rounded-full tw-object-contain print:tw-h-[30px] print:tw-w-[30px]"
                            [src]="'/derifiles/' + article.author.userProfilePicture"
                            (load)="imageLoaded.emit()" />
                    } @else {
                        <span class="tw-inline-flex tw-h-[50px] tw-w-[50px] tw-items-center tw-justify-center tw-rounded-full tw-bg-surface-brand-light tw-text-center print:tw-h-[30px] print:tw-w-[30px]">
                            <span class="material-symbols-rounded tw-text-[2rem]">person</span>
                        </span>
                    }
                    <span class="tw-font-bold tw-leading-5 print:tw-text-[0.625rem]">{{ article.author.firstName }} {{ article.author.lastName }}</span>
                </div>
                <div
                    class="tw-order-first tw-flex tw-justify-end sm:tw-order-last print:tw-hidden"
                    [ngClass]="{ '2xl:tw-hidden': (readMode$ | async) === ReadMode.View }">
                    <ng-content></ng-content>
                </div>
            </div>
            <div class="tw-text-3xl print:tw-text-[0.875rem] print:tw-leading-[1.0625rem]">{{ article.title }}</div>
            <div
                class="tw-flex tw-flex-col tw-gap-x-2.5 tw-gap-y-5 sm:tw-flex-row sm:tw-items-center sm:tw-justify-between print:tw-flex-col print:tw-gap-y-2.5"
                [ngClass]="{ 'tw-items-end': !article.mustRead }">
                <span class="tw-text-sm tw-leading-4.5 tw-text-secondary print:tw-self-start print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]">erstellt am {{ article.createdAt | date }} {{ article.createdAt | date: 'shortTime' }} Uhr | geändert am {{ article.updatedAt | date }} {{ article.updatedAt | date: 'shortTime' }} Uhr</span>
                <span
                    class="tw-flex tw-h-5.5 tw-justify-between print:tw-self-end"
                    [ngClass]="{
                        'tw-flex-1': article.mustRead
                    }">
                    @if (article.mustRead) {
                        <span class="material-symbols-rounded tw-text-1.5xl tw-text-warning print:tw-hidden">flag</span>
                    }
                    <span class="tw-inline-flex tw-items-center tw-gap-x-2"
                        ><span class="material-symbols-rounded tw-text-base tw-leading-4 print:tw-text-[0.9375rem] print:tw-leading-[0.9375rem]">visibility</span>
                        <span class="tw-text-sm tw-leading-4.5 print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]">{{ article.seenInformation.seenCount }}</span>
                    </span>
                </span>
            </div>
        </div>
        <tiptap-editor [editor]="article.body | quillToTiptap"></tiptap-editor>
        @if (article.attachments.length || (article.products.length && innerWidth() > 1280)) {
            <div
                class="tw-flex tw-flex-col tw-gap-y-10 print:tw-gap-y-5"
                [ngClass]="{ '2xl:tw-hidden': (readMode$ | async) === ReadMode.View }">
                <hr class="tw-border-border-separator" />
                <app-article-assets
                    [attachments]="article.attachments"
                    [products]="article.products" />
            </div>
        }
    </div>
}
