import { Component, EventEmitter, input, Output } from '@angular/core';
import { Article } from '../../../../routes/feed/category/types/contents.type';
import { DatePipe, NgClass } from '@angular/common';
import { ArticleAssetsComponent } from '../article-assets/article-assets.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { QuillToTiptapPipe } from '../../../pipes/quill-to-tiptap.pipe';
import { ArticleActionsComponent } from "../article-actions/article-actions.component";

@Component({
    selector: 'app-article-content',
    standalone: true,
    imports: [NgClass, DatePipe, ArticleAssetsComponent, NgxTiptapModule, QuillToTiptapPipe, ArticleActionsComponent],
    templateUrl: './article-content.component.html',
})
export class ArticleContentComponent {
    article = input<Article>();
    innerWidth = input<number>();

    @Output() imageLoaded = new EventEmitter<void>();
}
