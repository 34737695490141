import { HttpClient } from '@angular/common/http';
import { BaseApi } from './base.api';
import { environment } from '@shared/environments/environment';
import { BROKER_API } from '@shared/variables/apis';

export abstract class BrokerApi extends BaseApi {
    constructor(resourceName: string, http: HttpClient) {
        const environmentPath = `environments/${environment.id}`;
        const basePath = `${BROKER_API}/${environmentPath}/${resourceName}`;

        super(basePath, http);
    }
}
