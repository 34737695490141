import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, concatMap, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../api/contents.api';
import { Intro, IntroStatus } from '../types/contents.type';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UIActions } from '../../../../core/ui/store/ui.actions';
import * as UISelectors from '../../../../core/ui/store/ui.selectors';

export const ARTICLE_LOADING_LIMIT = 12;

type Loading = 'inactive' | 'initial' | 'next' | 'switch';

interface CategoryViewState {
    sessionId?: string;
    navigated: boolean;
    intros: {
        loading: Loading;
        offset: number;
        endReached: boolean;
        data: Array<Intro>;
    };
}

@Injectable()
export class CategoryViewStore extends ComponentStore<CategoryViewState> {
    constructor(
        private store: Store,
        private contentsApi: ContentsApi
    ) {
        super({
            navigated: false,
            intros: {
                loading: 'inactive',
                offset: 0,
                endReached: false,
                data: []
            }
        });
    }

    readonly createSession = this.effect(
        (
            params$: Observable<{
                categoryId: string;
                introStatus: IntroStatus;
                navigated: boolean;
            }>
        ) =>
            params$.pipe(
                tap(({ navigated }) => this.setLoading(navigated ? 'switch' : 'initial')),
                tap(() => this.store.dispatch(UIActions.closeSidebar())),
                switchMap(({ categoryId, introStatus, navigated}) =>
                    this.contentsApi.createSession(categoryId, introStatus).pipe(
                        tap(data => this.setSessionId(data.sessionId)),
                        tap(() => this.resetArticlePreviews(navigated)),
                        tap(() =>
                            this.loadArticlePreviews({
                                initial: true,
                                navigated
                            })
                        )
                    )
                )
            )
    );

    readonly loadNextSet = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setLoading('next')),
            tap(() =>
                this.loadArticlePreviews({
                    initial: false,
                    navigated: false
                })
            )
        )
    );

    readonly loadArticlePreviews = this.effect(
        (
            params$: Observable<{
                initial: boolean;
                navigated: boolean;
            }>
        ) =>
            params$.pipe(
                concatLatestFrom(() => [
                    this.sessionId$,
                    this.store.select(UISelectors.selectScrolledToEnd)
                ]),
                concatMap(([{ initial, navigated }, sessionId, multiplier]) =>
                    this.contentsApi
                        .getArticlePreviews(
                            sessionId,
                            ARTICLE_LOADING_LIMIT,
                            initial ? 0 : multiplier * ARTICLE_LOADING_LIMIT
                        )
                        .pipe(
                            tap(data => this.addArticlePreviews({ data: data.result, navigated })),
                            tap(() => this.setLoading('inactive'))
                        )
                )
            )
    );

    readonly setLoading = this.updater((state, loading: Loading) => ({
        ...state,
        intros: {
            ...state.intros,
            loading
        }
    }));

    readonly setSessionId = this.updater(
        (state, sessionId: string): CategoryViewState => ({
            ...state,
            sessionId
        })
    );

    readonly resetArticlePreviews = this.updater(
        (state, navigated: boolean): CategoryViewState => ({
            ...state,
            intros: {
                ...state.intros,
                data: navigated ? state.intros.data : [],
                offset: 0
            }
        })
    );

    readonly addArticlePreviews = this.updater(
        (state, payload: { data: Array<Intro>, navigated: boolean }): CategoryViewState => ({
            ...state,
            intros: {
                ...state.intros,
                data: payload.navigated ? payload.data : [...state.intros.data, ...payload.data],
                endReached: payload.data.length === 0
            }
        })
    );

    readonly sessionId$: Observable<string> = this.select(state => state.sessionId);

    readonly introsLoading$: Observable<Loading> = this.select(state => state.intros.loading);
    readonly introsOffset$: Observable<number> = this.select(state => state.intros.offset);
    readonly introsEndReached$: Observable<boolean> = this.select(state => state.intros.endReached);
    readonly intros$: Observable<Array<Intro>> = this.select(state => state.intros.data);
}
