import { Injectable } from '@angular/core';
import { BrokerApi } from '../../../../shared/api/broker.api';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../../../../shared/api/base.api';
import {
    Article,
    Intro,
    IntroStatus,
    IntrosData,
    SessionData,
} from '../types/contents.type';

@Injectable()
export class ContentsApi extends BrokerApi {
    constructor(http: HttpClient) {
        super('contents', http);
    }

    createSession(
        categoryId: string,
        introStatus: IntroStatus
    ): Observable<SessionData> {
        return this.post<ApiResponse<SessionData>>('sessions', {
            navigationItem: categoryId,
            tab: introStatus
        }).pipe(map(response => response.data));
    }

    getArticlePreviews(
        sessionId: string,
        limit: number,
        offset: number
    ): Observable<IntrosData> {
        return this.get<ApiResponse<IntrosData>>(`sessions/${sessionId}`, {
            params: {
                limit: limit.toString(),
                offset: offset.toString()
            }
        }).pipe(map(response => response.data));
    }

    getArticle(articleId: string): Observable<Article> {
        return this.get<ApiResponse<Article>>(articleId).pipe(
            map(response => response.data)
        )
    }

    deleteArticle(articleId: string): Observable<void> {
        return this.delete(`${articleId}`);
    }

    addBookmark(articleId: string): Observable<void> {
        return this.post(`${articleId}/bookmarks`, {});
    }

    removeBookmark(articleId: string): Observable<void> {
        return this.delete(`${articleId}/bookmarks`);
    }
}
