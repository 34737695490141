var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};

// src/index.ts
import qs from "qs";
function urlcat(baseUrlOrTemplate, pathTemplateOrParams, maybeParams = {}, config = {}) {
  if (typeof pathTemplateOrParams === "string") {
    const baseUrl = baseUrlOrTemplate;
    const pathTemplate = pathTemplateOrParams;
    const params = maybeParams;
    return urlcatImpl(pathTemplate, params, baseUrl, config);
  } else {
    const baseTemplate = baseUrlOrTemplate;
    const params = pathTemplateOrParams;
    return urlcatImpl(baseTemplate, params, void 0, config);
  }
}
function configure(rootConfig) {
  return (baseUrlOrTemplate, pathTemplateOrParams, maybeParams = {}, config = {}) => urlcat(baseUrlOrTemplate, pathTemplateOrParams, maybeParams, __spreadValues(__spreadValues({}, rootConfig), config));
}
function joinFullUrl(renderedPath, baseUrl, pathAndQuery) {
  if (renderedPath.length) {
    return join(baseUrl, "/", pathAndQuery);
  } else {
    return join(baseUrl, "?", pathAndQuery);
  }
}
function urlcatImpl(pathTemplate, params, baseUrl, config) {
  const { renderedPath, remainingParams } = path(pathTemplate, params);
  const cleanParams = removeNullOrUndef(remainingParams);
  const renderedQuery = query(cleanParams, config);
  const pathAndQuery = join(renderedPath, "?", renderedQuery);
  return baseUrl ? joinFullUrl(renderedPath, baseUrl, pathAndQuery) : pathAndQuery;
}
function query(params, config) {
  var _a, _b;
  if (Object.keys(params).length < 1) {
    return "";
  }
  const qsConfiguration = {
    format: (_b = (_a = config == null ? void 0 : config.objectFormat) == null ? void 0 : _a.format) != null ? _b : "RFC1738",
    arrayFormat: config == null ? void 0 : config.arrayFormat
  };
  return qs.stringify(params, qsConfiguration);
}
function subst(template, params) {
  const { renderedPath } = path(template, params);
  return renderedPath;
}
function path(template, params) {
  const remainingParams = __spreadValues({}, params);
  const renderedPath = template.replace(/:[_A-Za-z]+[_A-Za-z0-9]*/g, (p) => {
    const key = p.slice(1);
    validatePathParam(params, key);
    delete remainingParams[key];
    return encodeURIComponent(params[key]);
  });
  return { renderedPath, remainingParams };
}
function validatePathParam(params, key) {
  const allowedTypes = ["boolean", "string", "number"];
  if (!Object.prototype.hasOwnProperty.call(params, key)) {
    throw new Error(`Missing value for path parameter ${key}.`);
  }
  if (!allowedTypes.includes(typeof params[key])) {
    throw new TypeError(
      `Path parameter ${key} cannot be of type ${typeof params[key]}. Allowed types are: ${allowedTypes.join(", ")}.`
    );
  }
  if (typeof params[key] === "string" && params[key].trim() === "") {
    throw new Error(`Path parameter ${key} cannot be an empty string.`);
  }
}
function join(part1, separator, part2) {
  const p1 = part1.endsWith(separator) ? part1.slice(0, -separator.length) : part1;
  const p2 = part2.startsWith(separator) ? part2.slice(separator.length) : part2;
  return p1 === "" || p2 === "" ? p1 + p2 : p1 + separator + p2;
}
function removeNullOrUndef(params) {
  return Object.keys(params).filter((k) => notNullOrUndefined(params[k])).reduce((result, k) => {
    result[k] = params[k];
    return result;
  }, {});
}
function notNullOrUndefined(v) {
  return v !== void 0 && v !== null;
}
export {
  configure,
  urlcat as default,
  join,
  query,
  subst
};
