import { Router, Routes } from '@angular/router';
import { LoginPageComponent } from './routes/login/login-page.component';
import { FeedViewComponent } from './routes/feed/feed-view.component';
import { inject } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { Store } from '@ngrx/store';
import { AuthActions } from './core/auth/store/auth.actions';
import { CategoryViewComponent } from './routes/feed/category/category-view.component';
import { ArticleViewComponent } from './routes/feed/category/article/article-view.component';
import { ArticlePrintViewComponent } from './routes/print/article-print-view/article-print-view.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [
            () => {
                if (inject(AuthService).isRefreshTokenValid()) {
                    inject(Router).navigateByUrl('/feed');
                } else {
                    return true;
                }
            }
        ]
    },
    {
        path: 'feed',
        component: FeedViewComponent,
        canActivate: [
            () => {
                if (inject(AuthService).isRefreshTokenValid()) {
                    return true;
                } else {
                    inject(Store).dispatch(AuthActions.logOut());
                }
            }
        ],
        children: [
            {
                path: 'category/:categoryId',
                component: CategoryViewComponent,
                data: {
                    infiniteLoading: true
                }
            },
            {
                path: 'category/:categoryId/article/:articleId',
                component: ArticleViewComponent
            }
        ]
    },
    {
        path: 'print/article/:articleId',
        component: ArticlePrintViewComponent
    }
];
