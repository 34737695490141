import { Observable } from 'rxjs';
import { AuthToken, AuthUser } from '../types/auth.type';
import { BaseApi } from '../../../shared/api/base.api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DUSE_API } from '@shared/variables/apis';

@Injectable({
    providedIn: 'root'
})
export class AuthApi extends BaseApi {
    constructor(http: HttpClient) {
        const basePath = `${DUSE_API}/auth`;

        super(basePath, http);
    }

    getTokenUsingCredentials(username: string, password: string): Observable<AuthToken> {
        return this.get('token', {
            params: {
                includeRefreshToken: 'true'
            },
            basicAuth: {
                username,
                password
            }
        });
    }

    getTokenUsingRefreshToken(): Observable<AuthToken> {
        return this.get('token/refresh', {
            params: {
                refreshtoken: localStorage.getItem('refreshToken')
            }
        });
    }

    getAuthenticatedUser(): Observable<AuthUser> {
        return this.get('token/me?appId=mercury');
    }

    getRedirectTokenUsingRefreshToken(): Observable<string> {
        return this.post('token/redirect', localStorage.getItem('refreshToken'), { responseType: 'text' });
    }
}
