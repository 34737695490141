import { Component, EventEmitter, input, Output } from '@angular/core';
import { Article } from '../../../../routes/feed/category/types/contents.type';
import { ButtonComponent, ChromaDialog } from 'chroma-ui';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../dialogs/confirm-dialog/confirm-dialog.type';
import { filter } from 'rxjs';

@Component({
    selector: 'app-article-actions',
    standalone: true,
    imports: [ButtonComponent],
    templateUrl: './article-actions.component.html'
})
export class ArticleActionsComponent {
    article = input<Article>();

    @Output() clipboard = new EventEmitter<void>();
    @Output() bookmarked = new EventEmitter<boolean>();
    @Output() print = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    constructor(private dialog: ChromaDialog) {}

    deleteArticle(): void {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
            ConfirmDialogComponent,
            {
                data: {
                    title: 'Beitrag löschen',
                    message:
                        'Sind Sie sich sicher, dass Sie diesen Beitrag endgültig löschen wollen?',
                    type: 'delete'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(filter(result => result?.confirmed))
            .subscribe(() => this.delete.emit());
    }
}
