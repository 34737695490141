import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NavigationGroup } from '../types/displays.type';
import { UnseenData } from '../types/unseen.type';

export const NavigationActions = createActionGroup({
    source: 'Navigation',
    events: {
        'Load Groups': emptyProps(),
        'Set Groups': props<{ data: Array<NavigationGroup> }>(),
        'Expand Active Group': props<{ categoryId: string }>(),
        'Toogle Group': props<{ groupIndex: number }>(),
        'Patch Unseen': props<{ data: UnseenData }>()
    }
});
