import { Component, input, OnDestroy } from '@angular/core';
import { ChromaDialog, ChromaDialogRef, FileSizePipe } from 'chroma-ui';
import { AuthApi } from '../../../../core/auth/api/auth.api';
import { FactsheetDialogComponent } from '../../../../routes/feed/category/article/components/dialogs/factsheet-dialog/factsheet-dialog.component';
import { FactsheetDialogData } from '../../../../routes/feed/category/article/components/dialogs/factsheet-dialog/factsheet-dialog.type';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import urlcat from 'urlcat';

@Component({
    selector: 'app-article-assets',
    standalone: true,
    imports: [FileSizePipe],
    templateUrl: './article-assets.component.html',
    styles: ``
})
export class ArticleAssetsComponent implements OnDestroy {
    attachments = input<Array<any>>();
    products = input<Array<any>>();

    factsheetDialogRef: ChromaDialogRef<FactsheetDialogComponent>;

    constructor(
        private dialog: ChromaDialog,
        private authApi: AuthApi
    ) {}

    ngOnDestroy(): void {
        if (this.factsheetDialogRef) {
            this.factsheetDialogRef.close();
        }
    }

    openFactsheetDialog(link: string): void {
        this.authApi.getRedirectTokenUsingRefreshToken().subscribe(redirectToken => {
            this.factsheetDialogRef = this.dialog.open<
                FactsheetDialogComponent,
                FactsheetDialogData
            >(FactsheetDialogComponent, {
                defaultContainer: false,
                positionStrategy: new GlobalPositionStrategy(),
                data: {
                    link: urlcat(link, '/', { redirectToken })
                }
            });
        });
    }
}
