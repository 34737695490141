@if (article(); as article) {
    <div class="tw-inline-flex tw-gap-x-5">
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="clipboard.emit()">
            <span class="material-symbols-rounded">share</span>
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="bookmarked.emit(article.bookmarked)">
            @if (article.bookmarked) {
                <span class="material-symbols-rounded">bookmark_remove</span>
            } @else {
                <span class="material-symbols-rounded">bookmark_add</span>
            }
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="print.emit()">
            <span class="material-symbols-rounded">print</span>
        </button>
        <button
            type="button"
            chroma-button
            size="icon"
            appearance="outline"
            class="tw-cursor-default"
            (click)="deleteArticle()">
            <span class="material-symbols-rounded">delete</span>
        </button>
    </div>
}
