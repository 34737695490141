import { Environment } from './environment.type';

export const environment: Environment = {
    production: false,
    id: 'QQD3M0',
    name: 'Development',
    theme: 'default',
    logo: 'mercury-logo.svg',
    logoHeight: 45
};
