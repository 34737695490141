import { ConnectionPositionPair, OverlayModule, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, HostListener, input } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '@shared/environments/environment';
import { UIActions } from '../../../core/ui/store/ui.actions';
import { ImageReferenceResolverService } from '../../services/image-reference-resolver.service';
import * as AuthSelectors from '../../../core/auth/store/auth.selectors';
import { AuthActions } from '../../../core/auth/store/auth.actions';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { DERICON_ID_PROD, DERICON_ID_TEST } from '../../../../../../../shared/variables/dericon-id';
import { ButtonComponent, ChromaTooltipModule } from 'chroma-ui';
import * as UISelectors from '../../../core/ui/store/ui.selectors';
import { Platform } from '@angular/cdk/platform';

@Component({
    selector: 'app-top-bar',
    standalone: true,
    imports: [AsyncPipe, NgClass, NgStyle, ButtonComponent, OverlayModule, ChromaTooltipModule],
    templateUrl: './top-bar.component.html'
})
export class TopBarComponent {
    isAuthView = input();

    logo = environment.logo;
    logoHeight = environment.logoHeight;
    innerWidth = window.innerWidth;
    overlayPositions = [
        new ConnectionPositionPair(
            {
                originX: 'end',
                originY: 'bottom'
            },
            {
                overlayX: 'end',
                overlayY: 'top'
            }
        )
    ];
    scrollStrategy = this.sso.block();

    get dericonIdLink(): string {
        return `https://${environment.production ? DERICON_ID_PROD : DERICON_ID_TEST}`;
    }

    authUser$ = this.store.select(AuthSelectors.selectAuthUser);
    isSidebarOpen$ = this.store.select(UISelectors.selectIsSidebarOpen);
    isProfileMenuOpen$ = this.store.select(UISelectors.selectIsProfileMenuOpen);

    constructor(
        private store: Store,
        private imageResolver: ImageReferenceResolverService,
        private sso: ScrollStrategyOptions,
        private platform: Platform
    ) {}

    toggleSidebar(): void {
        this.store.dispatch(UIActions.toggleSidebar());
    }

    toggleProfileMenu(): void {
        this.store.dispatch(UIActions.toggleProfileMenu());
    }

    resolveDerifilesImage(ref: string): string {
        return this.imageResolver.resolveRemoteImagePath(ref);
    }

    logOut(): void {
        this.store.dispatch(AuthActions.logOut());
    }

    @HostListener('window:resize')
    onResize() {
        this.innerWidth = window.innerWidth;
    }

    platformSupportsMouseEvents() {
        return !this.platform.IOS && !this.platform.ANDROID;
    }
}
