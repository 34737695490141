import { Dialog } from "@angular/cdk/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { Injectable, InjectionToken, Injector, Type } from "@angular/core";
import { ChromaDialogRef } from "./dialog-ref";
import { ChromaDialogConfig } from "./dialog-config";
import { ChromaDialogContainer } from "./dialog-container";

export const CHROMA_DIALOG_DATA = new InjectionToken<any>('ChromaDialogData');

@Injectable({ providedIn: 'root' })
export class ChromaDialog {

    protected _dialog: Dialog;

    private readonly _dialogRefConstructor: Type<ChromaDialogRef<any>>;
    private readonly _dialogContainerType: Type<ChromaDialogContainer>;
    private readonly _dialogDataToken: InjectionToken<any>

    constructor(injector: Injector) {
        this._dialog = injector.get(Dialog);

        this._dialogRefConstructor = ChromaDialogRef;
        this._dialogContainerType = ChromaDialogContainer;
        this._dialogDataToken = CHROMA_DIALOG_DATA;
    }

    open<T, D = any, R = any>(
        component: ComponentType<T>,
        config?: ChromaDialogConfig<D>
    ): ChromaDialogRef<R> {
        let dialogRef: ChromaDialogRef<R>;

        config = { ...new ChromaDialogConfig(), ...config };

        if (config.defaultContainer) {
            config = {
                ...config,
                ...{
                    container: {
                        type: this._dialogContainerType,
                        providers: () => []
                    }
                }
            };
        }

        this._dialog.open(component, {
            ...config,
            backdropClass: ['tw-bg-init-bg/90', 'tw-backdrop-blur'],
            providers: (ref, cdkConfig) => {
                dialogRef = new this._dialogRefConstructor(ref);
                return [
                    { provide: this._dialogDataToken, useValue: cdkConfig.data },
                    { provide: this._dialogRefConstructor, useValue: dialogRef }
                ];
            }
        });

        return dialogRef!;
    }
}
