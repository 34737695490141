import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Article } from '../../types/contents.type';
import { Observable, map, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../api/contents.api';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RouterActions } from '../../../../../shared/store/router/router.actions';

interface ArticleViewState {
    loading: boolean;
    article?: Article;
}

@Injectable()
export class ArticleViewStore extends ComponentStore<ArticleViewState> {
    constructor(
        private store: Store,
        private conentsApi: ContentsApi
    ) {
        super({
            loading: false
        });
    }

    readonly loadArticle = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            tap(() => this.startLoading()),
            switchMap(articleId =>
                this.conentsApi.getArticle(articleId).pipe(tap(data => this.setArticle(data)))
            )
        )
    );

    readonly deleteArticle = this.effect((categoryId$: Observable<string>) =>
        categoryId$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([categoryId, articleId]) =>
                this.conentsApi.deleteArticle(articleId).pipe(
                    tap(() =>
                        this.store.dispatch(
                            RouterActions.navigate({
                                path: `/feed/category/${categoryId}`
                            })
                        )
                    )
                )
            )
        )
    );

    readonly addBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.conentsApi.addBookmark(articleId).pipe(tap(() => this.updateBookmark(true)))
            )
        )
    );

    readonly removeBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.conentsApi
                    .removeBookmark(articleId)
                    .pipe(tap(() => this.updateBookmark(false)))
            )
        )
    );

    readonly startLoading = this.updater(state => ({
        ...state,
        loading: true
    }));

    readonly setArticle = this.updater(
        (state, article: Article): ArticleViewState => ({
            ...state,
            article,
            loading: false
        })
    );

    readonly updateBookmark = this.updater(
        (state, bookmarked: boolean): ArticleViewState => ({
            ...state,
            article: {
                ...state.article,
                bookmarked
            }
        })
    );

    readonly article$: Observable<Article> = this.select(state => state.article);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
}
