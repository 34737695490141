<div class="md:tw-hidden">
    <div class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-justify-center tw-border-t tw-border-t-border-separator tw-bg-white tw-px-5 tw-py-3.75">
        <button
            type="button"
            chroma-button
            size="icon"
            (click)="createArticle()">
            <span class="material-symbols-rounded tw-text-xl tw-leading-none">stylus_note</span>
        </button>
    </div>
    <div class="tw-h-[69px]"></div>
</div>
