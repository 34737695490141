<div class="tw-fixed tw-left-0 tw-top-top-bar tw-z-10 tw-w-full tw-overflow-y-auto tw-bg-surface-brand-light tw-px-5 tw-pb-5 tw-pt-7.5 tw-scrollbar-gutter-stable sm:tw-w-screen sm:tw-pt-12.5 lg:tw-left-64 lg:tw-top-top-bar-lg lg:tw-w-[calc(100vw-256px)] lg:tw-px-8">
    <a
        [routerLink]="'/feed/category/' + categoryId"
        chroma-button
        appearance="outline"
        class="tw-cursor-default"
        >Zur Übersicht</a
    >
</div>
<div class="tw-h-15"></div>

<div #articleContainer>
    @if ((loading$ | async) === false) {
        @if (article$ | async; as article) {
            <div class="tw-flex tw-flex-col tw-gap-y-7.5 sm:tw-gap-y-2.5 2xl:tw-mr-[24.5rem] 2xl:tw-pr-2.5">
                <div class="tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                    <app-article-content [article]="article">
                        <app-article-actions
                            [article]="article"
                            (clipboard)="onCopyToClipboard()"
                            (bookmarked)="onArticleBookmark($event)"
                            (print)="onArticlePrint()"
                            (delete)="onArticleDelete()" />
                    </app-article-content>
                </div>
                <div class="tw-flex tw-flex-col tw-gap-y-5 tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
                    <div class="tw-text-xl tw-leading-6">Kommentare</div>
                    <div class="tw-flex tw-flex-col tw-divide-y tw-divide-border-separator">
                        @if (article.comments.length) {
                            @for (comment of article.comments; track $index) {
                                <div class="tw-flex tw-flex-col tw-gap-y-10 tw-py-10 first:tw-pt-0 last:tw-pb-0">
                                    <div class="tw-flex tw-flex-col tw-gap-y-2.5">
                                        <ng-container *ngTemplateOutlet="responseTmpl; context: { response: comment }"></ng-container>
                                    </div>
                                    @if (comment.replies.length) {
                                        <div class="tw-flex tw-gap-x-5">
                                            <div class="tw-w-0.5 tw-rounded-full tw-bg-primary-base"></div>
                                            <div class="tw-flex tw-flex-col tw-gap-y-10">
                                                @for (reply of comment.replies; track $index) {
                                                    <ng-container *ngTemplateOutlet="responseTmpl; context: { response: reply }"></ng-container>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        } @else {
                            <span class="tw-leading-5 tw-text-secondary">Bisher keine Kommentare vorhanden.</span>
                        }
                    </div>
                </div>
            </div>

            <div
                class="tw-fixed tw-bottom-[3.125rem] tw-right-8 tw-top-top-bar-lg tw-mt-[6.75rem] tw-hidden 2xl:tw-block"
                [ngClass]="{
                    'tw--mx-1.5 tw--mb-1.5 tw-overflow-y-auto tw-px-1.5 tw-pb-1.5 tw-scrollbar-gutter-stable': !hasScrollbar
                }">
                <div class="tw-h-full tw-w-[24.5rem] tw-overflow-y-auto tw-rounded tw-bg-white tw-px-5 tw-pb-7.5 tw-shadow">
                    <div
                        class="tw-sticky tw-top-0 tw-bg-white tw-py-7.5"
                        [ngClass]="{ 'tw-border-b tw-border-border-separator': article.attachments.length || article.products.length }">
                        <app-article-actions
                            [article]="article"
                            (clipboard)="onCopyToClipboard()"
                            (bookmarked)="onArticleBookmark($event)"
                            (print)="onArticlePrint()"
                            (delete)="onArticleDelete()" />
                    </div>
                    <div class="tw-pt-7.5">
                        <app-article-assets
                            [attachments]="article.attachments"
                            [products]="article.products" />
                    </div>
                </div>
            </div>
        }
    } @else {
        <div class="tw-fixed tw-left-0 tw-top-top-bar tw-flex tw-h-[calc(100%-theme(height.top-bar-lg))] tw-w-full tw-items-center tw-justify-center sm:tw-top-top-bar-lg lg:tw-left-64 lg:tw-w-[calc(100%-theme(space.64))]">
            <chroma-loading />
        </div>
    }
</div>

<ng-template
    #responseTmpl
    let-response="response">
    @if (asArticleResponse(response); as response) {
        <div class="tw-flex tw-flex-col tw-gap-y-2.5">
            <div class="tw-inline-flex tw-items-center tw-gap-x-2.5">
                @if (response.author.userProfilePicture) {
                    <img
                        class="tw-h-[50px] tw-w-[50px] tw-rounded-full tw-object-contain"
                        [src]="'/derifiles/' + response.author.userProfilePicture" />
                } @else {
                    <span class="tw-inline-flex tw-h-[50px] tw-w-[50px] tw-items-center tw-justify-center tw-rounded-full tw-bg-surface-brand-light tw-text-center">
                        <span class="material-symbols-rounded tw-text-[2rem]">person</span>
                    </span>
                }
                <span class="tw-inline-flex tw-flex-col tw-gap-y-1">
                    <span class="tw-font-bold tw-leading-5">{{ response.author.firstName }} {{ response.author.lastName }}</span>
                    <span class="tw-leading-5 tw-text-secondary">{{ response.createdAt | date }} {{ response.createdAt | date: 'shortTime' }}</span>
                </span>
            </div>
            <tiptap-editor [editor]="response.body | quillToTiptap"></tiptap-editor>
        </div>
    }
</ng-template>

@if (printViewVisible) {
    <iframe
        #printView
        class="tw-fixed tw--z-10"
        [src]="origin + '/print/article/' + articleId | safeResourceUrl"></iframe>
}
