import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Article } from '../../types/contents.type';
import { Observable, map, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../api/contents.api';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RouterActions } from '../../../../../shared/store/router/router.actions';
import { ArticleViewStore } from '../article-view.store';

interface ArticleBaseState {
    loading: boolean;
    article?: Article;
}

@Injectable()
export class ArticleBaseStore extends ComponentStore<ArticleBaseState> {
    constructor(
        private store: Store,
        private contentsApi: ContentsApi,
        private readonly articleViewStore: ArticleViewStore
    ) {
        super({
            loading: false
        });
    }

    readonly loadArticle = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            tap(() => this.startArticleLoading()),
            switchMap(articleId =>
                this.contentsApi.getArticle(articleId).pipe(
                    tap(data => this.setArticle(data)),
                    tap(() => this.articleViewStore.setArticleLoaded())
                )
            )
        )
    );

    readonly deleteArticle = this.effect((categoryId$: Observable<string>) =>
        categoryId$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([categoryId, articleId]) =>
                this.contentsApi.deleteArticle(articleId).pipe(
                    tap(() =>
                        this.store.dispatch(
                            RouterActions.navigate({
                                path: `/feed/category/${categoryId}`
                            })
                        )
                    )
                )
            )
        )
    );

    readonly addBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi.addBookmark(articleId).pipe(tap(() => this.updateBookmark(true)))
            )
        )
    );

    readonly removeBookmark = this.effect<void>(trigger$ =>
        trigger$.pipe(
            concatLatestFrom(() => this.article$.pipe(map(article => article.id))),
            switchMap(([, articleId]) =>
                this.contentsApi
                    .removeBookmark(articleId)
                    .pipe(tap(() => this.updateBookmark(false)))
            )
        )
    );

    readonly startArticleLoading = this.updater(state => ({
        ...state,
        loading: true
    }));

    readonly setArticle = this.updater(
        (state, article: Article): ArticleBaseState => ({
            ...state,
            article,
            loading: false
        })
    );

    readonly updateBookmark = this.updater(
        (state, bookmarked: boolean): ArticleBaseState => ({
            ...state,
            article: {
                ...state.article,
                bookmarked
            }
        })
    );

    readonly articleLoading$: Observable<boolean> = this.select(state => state.loading);
    readonly article$: Observable<Article> = this.select(state => state.article);
}
