import { FlexibleConnectedPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { normalizePassiveListenerOptions, Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    Component,
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const passiveListenerOptions = normalizePassiveListenerOptions({ passive: true });

@Directive({
    selector: '[chromaTooltip]',
    exportAs: 'chromaTooltip',
    standalone: true
})
export class ChromaTooltip implements OnDestroy {
    _overlayRef: OverlayRef | null;
    _tooltipInstance: TooltipComponent | null;

    private _portal: ComponentPortal<TooltipComponent>;
    private _pointerExitEventsInitialized = false;
    private readonly _tooltipComponent = TooltipComponent;

    @Input('chromaTooltip')
    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;

        this._setupPointerEnterEventsIfNeeded();
        this._updateTooltipMessage();
    }

    private _message = '';

    private readonly _passiveListeners: (readonly [string, EventListenerOrEventListenerObject])[] =
        [];

    private readonly _destroyed = new Subject<void>();

    constructor(
        private _overlay: Overlay,
        private _elementRef: ElementRef<HTMLElement>,
        private _viewContainerRef: ViewContainerRef,
        private _platform: Platform
    ) {}

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    show(): void {
        const overlayRef = this._createOverlay();
        this._portal =
            this._portal || new ComponentPortal(this._tooltipComponent, this._viewContainerRef);
        const instance = (this._tooltipInstance = overlayRef.attach(this._portal).instance);
        instance
            .afterHidden()
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this._detach());
        this._updateTooltipMessage();
        instance.show();
    }

    hide(): void {
        const instance = this._tooltipInstance;

        if (instance && instance.isVisible()) {
            instance.hide();
        }
    }

    toggle(): void {
        this._isTooltipVisible() ? this.hide() : this.show();
    }

    _isTooltipVisible(): boolean {
        return !!this._tooltipInstance && this._tooltipInstance.isVisible();
    }

    private _createOverlay(): OverlayRef {
        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withTransformOriginOn('.chroma-tooltip')
            .withFlexibleDimensions(false)
            .withViewportMargin(20);

        this._overlayRef = this._overlay.create({
            positionStrategy: strategy
        });

        this._updatePosition(this._overlayRef);

        this._overlayRef
            .outsidePointerEvents()
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this.hide());

        return this._overlayRef;
    }

    private _detach() {
        if (this._overlayRef && this._overlayRef.hasAttached()) {
            this._overlayRef.detach();
        }

        this._tooltipInstance = null;
    }

    private _updatePosition(overlayRef: OverlayRef) {
        const position = overlayRef.getConfig()
            .positionStrategy as FlexibleConnectedPositionStrategy;

        position.withPositions([
            {
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
                offsetY: 15
            },
            {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
                offsetY: -15
            }
        ]);
    }

    private _updateTooltipMessage() {
        if (this._tooltipInstance) {
            this._tooltipInstance.message = this.message;
        }
    }

    private _setupPointerEnterEventsIfNeeded() {
        if (this._platformSupportsMouseEvents()) {
            this._passiveListeners.push([
                'mouseenter',
                () => {
                    this._setupPointerExitEventsIfNeeded();
                    this.show();
                }
            ]);
        }

        this._addListeners(this._passiveListeners);
    }

    private _setupPointerExitEventsIfNeeded() {
        if (this._pointerExitEventsInitialized) {
            return;
        }
        this._pointerExitEventsInitialized = true;

        const exitListeners: (readonly [string, EventListenerOrEventListenerObject])[] = [];
        if (this._platformSupportsMouseEvents()) {
            exitListeners.push([
                'mouseleave',
                () => {
                    this.hide();
                }
            ]);
        }

        this._addListeners(exitListeners);
        this._passiveListeners.push(...exitListeners);
    }

    private _addListeners(listeners: (readonly [string, EventListenerOrEventListenerObject])[]) {
        listeners.forEach(([event, listener]) => {
            this._elementRef.nativeElement.addEventListener(
                event,
                listener,
                passiveListenerOptions
            );
        });
    }

    private _platformSupportsMouseEvents() {
        return !this._platform.IOS && !this._platform.ANDROID;
    }
}

@Component({
    selector: 'chroma-tooltip-component',
    templateUrl: 'tooltip.html',
    styleUrl: 'tooltip.scss',
    standalone: true
})
export class TooltipComponent {
    message: string;

    private _showTimeoutId: ReturnType<typeof setTimeout> | undefined;

  private _hideTimeoutId: ReturnType<typeof setTimeout> | undefined;

    @ViewChild('tooltip', {
        static: true
    })
    _tooltip: ElementRef<HTMLElement>;

    private _isVisible = false;

    private readonly _onHide: Subject<void> = new Subject();

    private readonly _showAnimation = 'chroma-tooltip-show';

    private readonly _hideAnimation = 'chroma-tooltip-hide';

    show(): void {
        this._showTimeoutId = setTimeout(() => {
            this._toggleVisibility(true);
            this._showTimeoutId = undefined;
          }, 0);
    }

    hide(): void {
        this._hideTimeoutId = setTimeout(() => {
            this._toggleVisibility(false);
            this._hideTimeoutId = undefined;
          }, 0);
    }

    afterHidden(): Observable<void> {
        return this._onHide;
    }

    isVisible(): boolean {
        return this._isVisible;
    }

    _handleAnimationEnd() {
        if (!this.isVisible()) {
            this._onHide.next();
        }
    }

    private _toggleVisibility(isVisible: boolean) {
        const tooltip = this._tooltip.nativeElement;
        const showClass = this._showAnimation;
        const hideClass = this._hideAnimation;
        tooltip.classList.remove(isVisible ? hideClass : showClass);
        tooltip.classList.add(isVisible ? showClass : hideClass);
        if (this._isVisible !== isVisible) {
            this._isVisible = isVisible;
        }
    }
}
