<div class="tw-fixed tw-top-0 tw-z-20 tw-w-full sm:tw-w-screen">
    <div class="tw-border-b tw-border-neutral-200 tw-bg-white sm:tw-overflow-y-auto sm:tw-scrollbar-gutter-stable">
        <div class="tw-mx-auto tw-px-5 lg:tw-px-8">
            <div class="tw-flex tw-justify-between tw-py-2.5">
                <div class="tw-hidden tw-w-56 tw-shrink-0 tw-items-center lg:tw-flex">
                    <a
                        class="tw-flex tw-items-center focus-visible:tw-outline-2 focus-visible:tw-outline-primary-focus lg:tw-h-auto"
                        href="/">
                        <img
                            class="focus-visible:tw-outline"
                            [src]="logo" />
                    </a>
                </div>

                @if (isAuthView()) {
                    <div class="lg:tw-hidden">
                        <button
                            type="button"
                            class="tw-w-9.5 tw-block tw-h-9.5 tw-overflow-hidden tw-rounded tw-text-black hover:tw-text-primary-base focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-primary-focus"
                            (click)="toggleSidebar()">
                            <span class="material-symbols-rounded tw-relative tw-bottom-1.25 tw-right-1.5 tw-text-5xl tw-font-light">{{ (isSidebarOpen$ | async) ? 'close' : 'menu' }}</span>
                        </button>
                    </div>

                    <div class="tw-flex tw-items-center tw-justify-between tw-divide-x tw-divide-neutral-400 tw-pl-5 sm:tw-w-full lg:tw-pl-8">
                        <div class="tw-hidden md:tw-block">
                            <button
                                type="button"
                                chroma-button
                                class="tw-cursor-default"
                                (click)="createArticle()">
                                <span>Erstellen</span>
                                <span
                                    class="material-symbols-rounded tw-text-xl tw-leading-none"
                                    icon-right
                                    >stylus_note</span
                                >
                            </button>
                        </div>
                        <button
                            type="button"
                            chroma-button
                            size="icon"
                            class="tw-cursor-default"
                            cdkOverlayOrigin
                            #profileMenuTrigger="cdkOverlayOrigin"
                            [class.tw-pointer-events-none]="isProfileMenuOpen$ | async"
                            (click)="toggleProfileMenu()">
                            <span class="material-symbols-rounded tw-text-xl tw-leading-none">account_circle</span>
                        </button>
                    </div>

                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="profileMenuTrigger"
                        [cdkConnectedOverlayOpen]="isProfileMenuOpen$ | async"
                        [cdkConnectedOverlayPositions]="overlayPositions"
                        [cdkConnectedOverlayHasBackdrop]="true"
                        [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
                        cdkConnectedOverlayBackdropClass="cdk-overlay-backdrop-soft"
                        (overlayOutsideClick)="toggleProfileMenu()"
                        (overlayKeydown)="toggleProfileMenu()">
                        <div class="tw-relative tw-left-5 tw-mt-[11px] tw-w-screen tw-border-neutral-100 tw-bg-white tw-px-5 tw-py-7.5 tw-shadow-soft sm:tw-left-0 sm:tw-mt-2.5 sm:tw-w-[420px] sm:tw-rounded sm:tw-border sm:tw-border-solid sm:tw-shadow-soft">
                            <div class="tw-flex tw-flex-col tw-gap-y-7.5">
                                @if (authUser$ | async; as user) {
                                    <div class="tw-flex tw-items-center tw-gap-x-2.5">
                                        @if (user.profilePictureId) {
                                            <img
                                                class="tw-h-10 tw-w-10 tw-rounded-full tw-object-contain"
                                                [src]="resolveDerifilesImage(user.profilePictureId)" />
                                        } @else {
                                            <span class="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-base">
                                                <span class="tw-text-base tw-leading-none tw-text-white">{{ user.firstname.charAt(0) }}{{ user.lastname.charAt(0) }}</span>
                                            </span>
                                        }
                                        <span class="tw-text-lg tw-font-semibold tw-text-primary-base">{{ user.firstname }} {{ user.lastname }}</span>
                                    </div>
                                    <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                                        <div class="tw-flex tw-flex-col tw-gap-y-1.25 tw-text-sm">
                                            <span class="tw-text-neutral-400">Benutzername</span>
                                            <span class="tw-text-black">{{ user.username }}</span>
                                        </div>
                                        <div class="tw-flex tw-flex-col tw-gap-y-1.25 tw-text-sm">
                                            <span class="tw-text-neutral-400">E-Mail</span>
                                            <span class="tw-text-black">{{ user.email }}</span>
                                        </div>
                                    </div>
                                }
                                <div class="tw-flex tw-justify-between">
                                    @if (platformSupportsMouseEvents()) {
                                        <a
                                            chroma-button
                                            appearance="outline"
                                            [href]="dericonIdLink"
                                            target="_blank"
                                            >Profileinstellungen</a
                                        >
                                    } @else {
                                        <button
                                            type="button"
                                            chroma-button
                                            appearance="outline"
                                            #tooltip="chromaTooltip"
                                            chromaTooltip="Profileinstellungen sind mobil leider nicht verfügbar. Bitte nutzen Sie hierfür die Desktop-Version von Mercury. Danke für Ihr Verständnis!"
                                            (click)="tooltip.toggle()">
                                            Profileinstellungen
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        chroma-button
                                        class="tw-cursor-default"
                                        (click)="logOut()">
                                        <span>Log out</span>
                                        <span
                                            class="material-symbols-rounded tw-text-xl tw-leading-none"
                                            icon-right
                                            >logout</span
                                        >
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                }
            </div>
        </div>
    </div>
</div>
@if (isAuthView()) {
    <div class="tw-h-top-bar lg:tw-h-top-bar-lg"></div>
}
