import {
    AfterViewInit,
    CUSTOM_ELEMENTS_SCHEMA,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonComponent, ChromaLoading, FileSizePipe } from 'chroma-ui';
import { map } from 'rxjs';
import { ContentsApi } from '../api/contents.api';
import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Article, ArticleResponse } from '../types/contents.type';
import { ArticleViewStore } from './store/article-view.store';
import { QuillToTiptapPipe } from '../../../../shared/pipes/quill-to-tiptap.pipe';
import { SafeResourceUrlPipe } from '../../../../shared/pipes/safe-resource-url.pipe';
import { ArticleAssetsComponent } from '../../../../shared/components/article/article-assets/article-assets.component';
import { ArticleContentComponent } from '../../../../shared/components/article/article-content/article-content.component';
import { ArticleActionsComponent } from '../../../../shared/components/article/article-actions/article-actions.component';

@Component({
    selector: 'app-article-view',
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        AsyncPipe,
        DatePipe,
        SafeResourceUrlPipe,
        FileSizePipe,
        QuillToTiptapPipe,
        NgTemplateOutlet,
        ButtonComponent,
        ChromaLoading,
        NgxTiptapModule,
        ArticleContentComponent,
        ArticleAssetsComponent,
        ArticleActionsComponent
    ],
    providers: [ArticleViewStore, ContentsApi],
    templateUrl: './article-view.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ArticleViewComponent implements OnInit, AfterViewInit {
    @ViewChild('articleContainer') articleContainer: ElementRef;
    @ViewChild('printView', { static: false }) printView: ElementRef<HTMLIFrameElement>;

    categoryId: string;
    articleId: string;
    innerWidth = window.innerWidth;
    origin = window.location.origin;
    printViewVisible = false;
    hasScrollbar = false;
    observer: ResizeObserver;

    article$ = this.articleViewStore.article$;
    loading$ = this.articleViewStore.loading$;

    constructor(
        private route: ActivatedRoute,
        private readonly articleViewStore: ArticleViewStore
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(
                map(params => ({
                    categoryId: params.categoryId,
                    articleId: params.articleId
                }))
            )
            .subscribe(({ categoryId, articleId }) => {
                this.categoryId = categoryId;
                this.articleId = articleId;

                this.articleViewStore.loadArticle(articleId);
            });

        window.addEventListener('message', event => {
            if (event.data === 'onafterprint') {
                this.printViewVisible = false;
            }
        });
    }

    ngAfterViewInit(): void {
        this.observer = new ResizeObserver(() => {
            this.hasScrollbar = document.body.scrollHeight > this.innerWidth;
        });

        this.observer.observe(this.articleContainer.nativeElement);
    }

    asArticle(article: any): Article {
        return article as Article;
    }

    asArticleResponse(response: any): ArticleResponse {
        return response as ArticleResponse;
    }

    onCopyToClipboard(): void {
        navigator.clipboard.writeText(window.location.href);
    }

    onArticleBookmark(bookmarked: boolean): void {
        if (bookmarked) {
            this.articleViewStore.removeBookmark();
        } else {
            this.articleViewStore.addBookmark();
        }
    }

    onArticlePrint(): void {
        this.printViewVisible = true;
    }

    onArticleDelete(): void {
        this.articleViewStore.deleteArticle(this.categoryId);
    }

    @HostListener('window:resize')
    onResize() {
        this.innerWidth = window.innerWidth;
    }
}
