import { Pipe, PipeTransform } from '@angular/core';
import { Delta } from 'quill';
import { Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Image from '@tiptap/extension-image';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

@Pipe({
    name: 'quillToTiptap',
    standalone: true
})
export class QuillToTiptapPipe implements PipeTransform {
    transform(delta: Delta): any {
        return new Editor({
            editable: false,
            extensions: [
                Document,
                Text,
                Bold,
                Italic,
                Paragraph.configure({
                    HTMLAttributes: {
                        class: 'tw-pt-5 first-of-type:tw-pt-0 print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]'
                    }
                }),
                Image
            ],
            content: new QuillDeltaToHtmlConverter(delta.ops).convert()
        });
    }
}
