import { createReducer, on } from '@ngrx/store';
import { UIState } from './ui.state';
import { UIActions } from './ui.actions';
import { AuthActions } from '../../auth/store/auth.actions';
import * as NgrxRouterActions from '@ngrx/router-store';

export const uiFeatureKey = 'ui';

export const initialState: UIState = {
    isSidebarOpen: false,
    isProfileMenuOpen: false,
    inifiniteLoading: false,
    scrolledToEnd: 0
};

export const uiReducer = createReducer(
    initialState,
    on(UIActions.toggleSidebar, state => ({
        ...state,
        isSidebarOpen: !state.isSidebarOpen
    })),
    on(UIActions.closeSidebar, state => ({
        ...state,
        isSidebarOpen: false
    })),
    on(UIActions.toggleProfileMenu, state => ({
        ...state,
        isProfileMenuOpen: !state.isProfileMenuOpen,
        isSidebarOpen: false
    })),
    on(AuthActions.logOut, state => ({
        ...state,
        isProfileMenuOpen: false
    })),
    on(UIActions.setInfiniteLoading, (state, { loading }) => ({
        ...state,
        inifiniteLoading: loading
    })),
    on(UIActions.scrolledToEnd, state => ({
        ...state,
        scrolledToEnd: state.scrolledToEnd + 1
    })),
    on(UIActions.resetScrolledToEnd, state => ({
        ...state,
        scrolledToEnd: 0
    })),
    on(NgrxRouterActions.routerNavigatedAction, state => ({
        ...state,
        scrolledToEnd: 0
    }))
);
