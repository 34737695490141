import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private jwtHelperService: JwtHelperService;

    constructor() {
        this.jwtHelperService = new JwtHelperService();
    }

    isRefreshTokenValid(): boolean {
        return !this.jwtHelperService.isTokenExpired(localStorage.getItem('refreshToken'));
    }
}
