<div class="tw-h-full tw-w-screen tw-overflow-y-auto tw-py-7.5 sm:tw-px-5 sm:tw-scrollbar-gutter-stable lg:tw-w-[calc(100vw-theme(space.64))] lg:tw-px-8 2xl:tw-py-12.5">
    <chroma-dialog-header
        title="Neuen Artikel erstellen"
        [largeHeader]="true"
        (closeDialog)="closeDialog()" />
    <div class="tw-gap-y tw-flex tw-flex-col tw-gap-y-7.5 2xl:tw-gap-y-10">
        <div class="tw-bg-white tw-px-5 tw-py-7.5 sm:tw-rounded sm:tw-shadow">
            <tiptap-editor [editor]="editor" />
        </div>
        <div class="tw-flex tw-justify-end tw-px-5 sm:tw-px-0">
            <button
                type="button"
                chroma-button
                [disabled]="editor.isEmpty"
                (click)="log()">
                Artikel veröffentlichen
            </button>
        </div>
    </div>
</div>
