import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ButtonComponent, ChromaDialogHeader, ChromaDialogRef } from 'chroma-ui';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Heading from '@tiptap/extension-heading';
import Paragraph from '@tiptap/extension-paragraph';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-create-article-dialog',
    standalone: true,
    imports: [ChromaDialogHeader, ButtonComponent, NgxTiptapModule, FormsModule],
    templateUrl: './create-article-dialog.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateArticleDialogComponent {
    editor = new Editor({
        extensions: [
            Document,
            Text,
            Heading,
            Paragraph,
            BulletList,
            OrderedList,
            ListItem,
            Link,
            Bold,
            Italic,
            Underline,
            Strike,
            TextStyle,
            TextAlign,
            Color,
            Dropcursor,
            Gapcursor,
            Table,
            TableRow,
            TableHeader,
            TableCell,
            Image.configure({
                allowBase64: true,
                inline: true
            })
        ]
    });

    constructor(private dialogRef: ChromaDialogRef<CreateArticleDialogComponent>) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    log(): void {
        console.log(this.editor.getJSON());
    }
}
