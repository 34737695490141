import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { routes } from './app.routes';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { authFeatureKey, authReducer } from './core/auth/store/auth.reducer';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from './core/auth/store/auth.effects';
import { uiFeatureKey, uiReducer } from './core/ui/store/ui.reducer';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterEffects } from './shared/store/router/router.effects';
import { JwtInterceptor } from './core/auth/jwt.interceptor';
import {
    navigationFeatureKey,
    navigationReducer
} from './core/navigation/store/navigation.reducer';
import { NavigationEffects } from './core/navigation/store/navigation.effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { UIEffects } from './core/ui/store/ui.effects';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            routes,
            withRouterConfig({
                paramsInheritanceStrategy: 'always'
            })
        ),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        provideStore(),
        provideRouterStore(),
        provideStoreDevtools(),
        provideState({
            name: 'router',
            reducer: routerReducer
        }),
        provideState({
            name: authFeatureKey,
            reducer: authReducer
        }),
        provideState({
            name: uiFeatureKey,
            reducer: uiReducer
        }),
        provideState({
            name: navigationFeatureKey,
            reducer: navigationReducer
        }),
        provideEffects(AuthEffects, RouterEffects, UIEffects, NavigationEffects)
    ]
};
