import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, switchMap, tap } from 'rxjs';
import { ContentsApi } from '../../../feed/category/api/contents.api';
import { Article } from '../../../feed/category/types/contents.type';

interface ArticlePrintViewState {
    loaded: boolean;
    article?: Article;
}

@Injectable()
export class ArticlePrintViewStore extends ComponentStore<ArticlePrintViewState> {
    constructor(private conentsApi: ContentsApi) {
        super({
            loaded: false
        });
    }

    readonly loadArticle = this.effect((articleId$: Observable<string>) =>
        articleId$.pipe(
            switchMap(articleId =>
                this.conentsApi.getArticle(articleId).pipe(
                    tap(data => this.setArticle(data))
                )
            )
        )
    );

    readonly setArticle = this.updater(
        (state, article: Article): ArticlePrintViewState => ({
            ...state,
            article,
            loaded: true
        })
    );

    readonly article$: Observable<Article> = this.select(state => state.article);
    readonly articleLoaded$: Observable<boolean> = this.select(state => state.loaded);
}
