import { Pipe, PipeTransform } from '@angular/core';
import { Delta } from 'quill';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';

@Pipe({
    name: 'quillToTiptap',
    standalone: true
})
export class QuillToTiptapPipe implements PipeTransform {
    transform(delta: Delta): any {
        return new Editor({
            editable: false,
            extensions: [
                StarterKit.configure({
                    paragraph: {
                        HTMLAttributes: {
                            class: 'tw-pt-5 first-of-type:tw-pt-0 print:tw-text-[0.625rem] print:tw-leading-[0.9375rem]'
                        }
                    }
                }),
                Image
            ],
            content: {
                type: 'doc',
                content: delta.ops.map(op => {
                    if (op.insert) {
                        return {
                            type: 'paragraph',
                            content: [
                                {
                                    type: 'text',
                                    text: op.insert
                                }
                            ]
                        };
                    } else if (op.attributes.embed) {
                        return {
                            type: 'paragraph',
                            content: [
                                {
                                    type: 'image',
                                    attrs: {
                                        src: op.attributes.embed.source
                                    }
                                }
                            ]
                        };
                    }
                })
            }
        });
    }
}
