import { Component } from '@angular/core';
import { ButtonComponent, ChromaDialog } from 'chroma-ui';
import { CreateArticleDialogComponent } from '../dialogs/create-news-dialog/create-article-dialog.component';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';

@Component({
    selector: 'app-bottom-bar',
    standalone: true,
    imports: [ButtonComponent],
    templateUrl: './bottom-bar.component.html'
})
export class BottomBarComponent {
    constructor(private dialog: ChromaDialog) {}

    createArticle(): void {
        this.dialog.open(CreateArticleDialogComponent, {
            defaultContainer: false,
            positionStrategy: new GlobalPositionStrategy()
        });
    }
}