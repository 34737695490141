import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UIActions = createActionGroup({
    source: 'UI',
    events: {
        'Set Top Bar Height': props<{ height: number }>(),
        'Toggle Sidebar': emptyProps(),
        'Close Sidebar': emptyProps(),
        'Toggle Profile Menu': emptyProps(),
        'Set Infinite Loading': props<{ loading: boolean }>(),
        'Scrolled To End': emptyProps(),
        'Reset Scrolled To End': emptyProps()
    }
});
