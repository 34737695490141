import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@shared/environments/environment';
import { MERCURY_TEST_DOMAIN } from '../../../../../../shared/variables/test-domains';

export abstract class BaseApi {
    basePath: string;
    
    constructor(
        protected resourcePath: string,
        protected http: HttpClient
    ) {
        const baseDomain = environment.production ? window.location.origin : MERCURY_TEST_DOMAIN;
        this.basePath = `${baseDomain}/${resourcePath}`;
    }

    get<T>(endpoint: string, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };

        if (endpoint.length > 0) {
            return this.http
                .get<T>(`${this.basePath}/${endpoint}`, options);
        } else {
            return this.http
                .get<T>(`${this.basePath}`, options);
        }
    }

    post<T>(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };
        return this.http.post<T>(`${this.basePath}/${endpoint}`, body, options);
    }

    delete<T>(endpoint: string, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };

        return this.http.delete<T>(`${this.basePath}/${endpoint}`, options);
    }

    private getHeaders(apiOptions: ApiOptions): { [header: string]: string | string[] } {
        const headers = apiOptions.headers || {};

        if (apiOptions.body) {
            headers['Content-Type'] = 'application/json';
        }

        if (apiOptions.basicAuth) {
            headers.Authorization = `Basic ${window.btoa(
                String.fromCharCode(
                    ...new TextEncoder().encode(
                        apiOptions.basicAuth.username + ':' + apiOptions.basicAuth.password
                    )
                )
            )}`;
        }

        return headers;
    }
}

export interface ApiResponse<D> {
    data: D;
}

export interface ApiOptions {
    token?: string;
    basicAuth?: {
        username: string;
        password: string;
    };
    body?: any;
    headers?: {
        [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    observe?: any;
    reportProgress?: boolean;
    responseType?: any;
}
